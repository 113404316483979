
<template>
  <div class="AccountRecoveryBefore">
    <div class="titleBox d-flex align-items-center">
      <div class="imgBox2" @click="$router.go(-1)">
        <img src="../../assets/images/login/chevron-left.png" alt="" />
      </div>
      <div class="title">{{ $t("AuthPage.cb5@account_recovery") }}</div>
    </div>

    <form class="mt-4">
      <div class="form-group">
        <label for="exampleInputEmail1">{{
          $t("AuthPage.5cb@email_address")
        }}</label>
        <input
          type="email"
          class="form-control mb-0"
          id="exampleInputEmail1"
          :placeholder="$t('AuthPage.5cb@email_address')"
          v-model="email"
        />
      </div>
      <div class="form-group" v-for="(v, i) in problemList" :key="i">
        <label for="exampleInputEmail2">{{ v.problem }}</label>
        <input
          type="text"
          class="form-control mb-0"
          id="exampleInputEmail2"
          placeholder="XXX"
          v-model="params[i]"
        />
      </div>
      <div class="mb-3 pb-5">
        <!-- <router-link :to="{ name: 'auth1.accountRecoveryAfter' }"> -->
          
          <button
            class="btn btn-primary float-right mt-3"
            @click="applyUnfreeze"
          >
            {{ $t("AuthPage.3ba@submit") }}
          </button>
        <!-- </router-link> -->
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    email: "",
    problemList: [],
    params: [],
  }),
  mounted () {
    this.getUnfreezeProblem();
  },
  methods: {
    // 申请恢复冻结获取问题
    getUnfreezeProblem () {
      this.$http.getUnfreezeProblem().then((res) => {
        // console.log(res);
        if (res.status == 200) {
          this.problemList = res.data;
        }
      });
    },
    // 申请恢复冻结
    applyUnfreeze () {
      let user_frozen_ids = ""
      this.problemList.forEach(v => {
        user_frozen_ids += `${v.id},`
      })
      let answer = this.params.toString();
      const data = {
        email: this.email,
        user_frozen_ids,
        answer
      }
      this.$http.applyUnfreeze(data).then((res) => {
        // console.log(res);
        if (res.status == 200) {
          this.$router.push(`/auth/account-recovery-after?email=${this.email}`)
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.AccountRecoveryBefore {
  .titleBox {
    margin-bottom: 83px;
    position: relative;
    .imgBox2 {
      position: absolute;
      left: -10px;
      cursor: pointer;
    }
    .title {
      font-size: 28px;
      padding-left: 20px;
      font-weight: 400;
      color: #3f414d;
    }
  }
}
</style>
